import React from "react";

const DevelopmentExpertise = () => {
  return (
    <div className="skills__content">
      <div className="skills__box">
        <div className="skills__group">
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Custom Applications</h3>
              <span className="skills__level">Tailored solutions to meet your business needs</span>
            </div>
          </div>
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Web Development</h3>
              <span className="skills__level">Front-end and Back-end Development</span>
            </div>
          </div>
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Mobile Apps</h3>
              <span className="skills__level">iOS and Android Development</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentExpertise;
