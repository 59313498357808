import React from "react";
import "./skills.css";
// import Frontend from "./Frontend";
// import Backend from "./Backend";
// import Expertise from "./Expertise";
import DevelopmentExpertise from "./DevelopmentExpertise";
import AIExpertise from "./AIExpertise";

const Skills = () => {
  return (
    <section className="skills section" id="skills">
      <h2 className="section__title">Our Expertise</h2>
      <span className="section__subtitle">What We Offer</span>

      <div className="skills__container container grid">
        {/* {/* <Frontend /> */}
        {/* <Backend />  */}
        {/* <Expertise /> */}
        <DevelopmentExpertise />
        <AIExpertise />
      </div>
    </section>
  );
};

export default Skills;
