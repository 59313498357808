import React, { useState } from "react";
// import "./qualification.css"; // 
import "./MissionVision.css";

const MissionVision = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="qualification section">
      <h2 className="section__title">Mission & Vision</h2>
      <span className="section__subtitle">Our Guiding Principles</span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
              toggleState === 1
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(1)}
          >
            <i className="uil uil-bullseye qualification__icon"></i>
            Mission
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(2)}
          >
            <i className="uil uil-eye qualification__icon"></i>
            Vision
          </div>

          <div
            className={
              toggleState === 3
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(3)}
          >
            <i className="uil uil-star qualification__icon"></i>
            Core Values
          </div>
        </div>

        <div className="qualification__sections">
          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Our Mission</h3>
                <p className="qualification__description">
                  At XOX Group, our mission is to leverage innovative AI and software development solutions to empower businesses to achieve their full potential. We are dedicated to providing cutting-edge technology and unparalleled service to help our clients thrive in the digital age.
                </p>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Our Vision</h3>
                <p className="qualification__description">
                  Our vision is to become a global leader in AI and software development, renowned for our commitment to excellence and our ability to drive digital transformation across various industries. We aim to create a future where technology and business work seamlessly together to achieve extraordinary results.
                </p>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
          </div>

          <div
            className={
              toggleState === 3
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Core Values</h3>
                <ul className="qualification__description">
                  <li className="qualification__item">
                    <i className="uil uil-check-circle qualification__icon"></i>
                    Innovation
                  </li>
                  <li className="qualification__item">
                    <i className="uil uil-check-circle qualification__icon"></i>
                    Integrity
                  </li>
                  <li className="qualification__item">
                    <i className="uil uil-check-circle qualification__icon"></i>
                    Customer Focus
                  </li>
                  <li className="qualification__item">
                    <i className="uil uil-check-circle qualification__icon"></i>
                    Excellence
                  </li>
                  <li className="qualification__item">
                    <i className="uil uil-check-circle qualification__icon"></i>
                    Collaboration
                  </li>
                </ul>
              </div>

              <div>
                <span className="qualification__rounder"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVision;
