import React from "react";

const AIExpertise = () => {
  return (
    <div className="skills__content">
      <div className="skills__box">
        <div className="skills__group">
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Machine Learning</h3>
              <span className="skills__level">Predictive analytics and model building</span>
            </div>
          </div>
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Data Analysis</h3>
              <span className="skills__level">Insightful data visualization and reporting</span>
            </div>
          </div>
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">AI Integration</h3>
              <span className="skills__level">Seamless integration with existing systems</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIExpertise;
