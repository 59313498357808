import Image1 from "../../assets/testimonial1.png";
import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/testimonial3.png";

export const Data = [
  {
    id: 1,
    image: Image1,
    title: "Johnson",
    description:
      "XOX Group provided exceptional service and delivered a robust web solution that exceeded our expectations.",
  },
  {
    id: 2,
    image: Image2,
    title: "Michael Brown",
    description:
      "The team at XOX Group is highly skilled and professional. Their AI solutions have significantly improved our business operations.",
  },
  {
    id: 3,
    image: Image3,
    title: "Emily Davis",
    description:
      "We are extremely satisfied with the mobile app development services from XOX Group. The app is user-friendly and has received great feedback.",
  },
];
